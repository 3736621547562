import React, { useState, useEffect, useCallback } from 'react';
import './Home.css';
const MAX_POSTS = 1;
const imageURL = '/aiden2.png'; // Updated path assuming aiden2.png is in the public folder

const homeContent = (
    <div className="home">
        <header className="header">
            <div className="logo"></div>
            <h1>Welcome to Aiden Arun's 2nd Birthday Celebration!</h1>
            <p>Aiden Arun</p>
        </header>
        <img src={imageURL} alt="Aiden Arun" style={{ maxWidth: '100%', height: 'auto' }} />
        <footer className="footer">
        <p>Powered by <a href="https://aidenarun.com/" target="_blank"rel="noopener noreferrer">Aiden Arun</a></p>
        </footer>
    </div>
);
// Debounce function (can be defined outside of the component)
function debounce(func, wait, immediate) {
    let timeout;
    return function() {
      const context = this, args = arguments;
      const later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

const Home = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [allPostsLoaded, setAllPostsLoaded] = useState(false); // Add this line

    // Load more posts logic
    const loadMorePosts = useCallback(async () => {
        setLoading(true);

        // Simulate dynamic content loading
        // Inside loadMorePosts function
    setTimeout(() => {
    if (page >= MAX_POSTS) {
        setAllPostsLoaded(true); // No more posts to load
    } else {
        const newPosts = [`Post ${page}`, `Post ${page + 1}`];
        setPosts(prevPosts => [...prevPosts, ...newPosts]);
        setPage(page => page + 2);
    }
    setLoading(false);
}, 1000);
    }, [page]);

    // Infinite scrolling logic
    useEffect(() => {
        const handleScroll = () => {
          // If loading or all posts are loaded, don't do anything
          if (loading || allPostsLoaded) return;
      
          // Check if the user is near the bottom of the page, add threshold for mobile browsers
          const threshold = 100; // Pixels from the bottom to start loading more posts
          if (window.innerHeight + document.documentElement.scrollTop + threshold >= document.documentElement.offsetHeight) {
            loadMorePosts();
          }
        };
      
        // Debounce the scroll event
        const debouncedHandleScroll = debounce(handleScroll, 100);
      
        window.addEventListener('scroll', debouncedHandleScroll);
        return () => window.removeEventListener('scroll', debouncedHandleScroll);
      }, [loading, loadMorePosts, allPostsLoaded]); // Add allPostsLoaded to dependencies array

    return (
        <div className="home">
            {posts.length === 0 && homeContent}
            {posts.map((post, index) => (
                <p key={index}>{post}</p>
            ))}
            {loading && <p>Loading...</p>}
        </div>
    );
};

export default Home;
